/* Add this rule to disable text selection globally */
* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Standard syntax */
}

.container {
  color: #000;
}

.row-container {
  /* height: 125px; */
  padding: 10px;
  padding-bottom: 0px;
}

.col-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-weight: bold;
}

.col-container:hover {
  cursor: pointer;
}

.col-container--detail-convert {
  background: linear-gradient(
    145deg,
    rgba(25, 20, 100, 1) 0%,
    rgba(50, 50, 150, 1) 100%
  );
}

.col-container--batch {
  background: linear-gradient(
    145deg,
    rgba(100, 30, 150, 1) 0%,
    rgba(150, 50, 200, 1) 100%
  );
}

.content-container {
  height: calc(100% - 125px);
  padding: 10px;
}

.file-title {
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

/* livechat.css */
.livechat-widget-container {
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.tab-container {
  margin-top: 60px;
}

.button-text {
  font-family: "Clash Grotesk";
  font-size: 20px;
  font-weight: 200;
  line-height: 26.4px;
}

.hover-grey {
  transition: background-color 0.4s ease;
}

.hover-grey:hover {
  background-color: #eaeaea !important;
}
