.main {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ant-modal-body {
  padding: 0;
}
.ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.modal-footer {
  border-radius: 20px;
  display: flex;
  gap: 20px;
  padding: 12px 40px !important;
  border-top: none !important;
  background-color: rgb(238, 240, 240);
}

.modal-footer > button {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 32px;
  transition: all 0.3ms ease-in-out;
}

.modal-footer .exit {
  color: rgb(128, 0, 0);
  background-color: rgb(236, 216, 218);
}

.exit:hover {
  background-color: rgb(248, 211, 211);
}

.modal-footer .save {
  color: white;
  background-color: rgb(36, 226, 36);
}

.save:hover {
  background-color: rgb(25, 214, 25);
}

.close-alert .alert-wrapper {
  width: 570px;
  max-width: 100%;
  padding: 20px;
}

.close-alert .alert-head {
  padding: 0;
}

.close-alert .alert-title {
  color: #2a2a2a;
  font-family: "Clash Grotesk";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.close-alert .alert-sub-title {
  color: #666;
  font-family: "Clash Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
  margin-top: 8px;
}

.close-alert .alert-button-group {
  justify-content: center;
  gap: 10px;
  padding: 0;
  margin-top: 40px;
}

.close-alert .alert-button {
  border-radius: 6px;
  flex: 1;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.close-alert .alert-button:nth-of-type(1) {
  border: 1.5px solid #525252;
}

.close-alert .alert-button:nth-of-type(1):hover {
  background-color: #f5f5f5;
  border-color: #666;
  transform: translateY(-1px);
}

.close-alert .alert-button:nth-of-type(2) {
  background-color: #f453c4;
}

.close-alert .alert-button:nth-of-type(2):hover {
  background-color: #c7429f;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(244, 83, 196, 0.2);
}

.close-alert .alert-button-inner {
  justify-content: center;
  color: #525252;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
}

.close-alert .alert-button:nth-of-type(2) .alert-button-inner {
  color: #f8f8f8;
}

.close-alert .alert-button:nth-of-type(1) .alert-button-inner::before {
  content: url("../../../../assets/SvgEditor/RedCross.svg");
  display: inline-block;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.close-alert .alert-button:nth-of-type(2) .alert-button-inner::before {
  content: url("../../../../assets/SvgEditor/ForwardError.svg");
  display: inline-block;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

/* Active states for better interaction feedback */
.close-alert .alert-button:active {
  transform: translateY(0);
}
