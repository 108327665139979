.name-see-all {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 10px 10px;
}

.name-see-all > h3 {
  margin: 0;
  text-transform: capitalize;
  font-family: "Clash Grotesk";
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  color: #2a2a2a;
}

.name-see-all > button {
  background-color: transparent;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
}

.name-see-all > button:hover {
  color: rgb(64, 64, 241);
  text-decoration: underline;
  text-underline-offset: 2px;
}

.cardsHolder {
  width: 100%;
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
}

.cardsHolder::-webkit-scrollbar {
  display: none;
}

.previous {
  height: 20px;
  width: 20px;
  padding: 10px;
  background-color: white;
  border: 1px solid rgb(206, 206, 209);
  border-radius: 50%;
  position: absolute;
  color: rgb(28, 31, 31);
  left: 0;
  top: 50%;
  z-index: 1000;
  cursor: pointer;
}

.previous,
.next {
  transition: all 0.3s ease-in-out;
  color: #000;
  background-color: #fff;
}

.previous:hover,
.next:hover {
  color: #fff;
  background-color: rgb(132, 144, 253);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.next {
  height: 20px;
  width: 20px;
  padding: 10px;
  background-color: white;
  /* border: 1px solid rgb(206, 206, 209); */
  border-radius: 50%;
  position: absolute;
  color: rgb(28, 31, 31);
  right: 0;
  top: 50%;
  z-index: 1000;
  cursor: pointer;
}

.showmore {
  margin: 10px;
  display: flex;
  align-items: center;
  /* padding: 0 40px; */
}

.icon {
  border: 1px solid rgb(226, 37, 158);
  height: 50px;
  width: 50px;
  display: grid;
  color: rgb(226, 37, 158);
  place-items: center;
  border-radius: 14px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.icon:hover {
  background-color: rgb(226, 37, 158);
  color: white;
}

.parent {
  width: 150px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.parent > p {
  margin: 0;
  cursor: pointer;
}

.parent > p:hover {
  color: rgb(226, 37, 158);
}

.viewall-text {
  font-family: "Clash Grotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #484848;
}

.viewall-text:hover {
  text-decoration: underline;
  text-decoration-color: rgb(226, 37, 158); /* Adjusted for transparency */
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f3f3f3; */
  padding: 50px 20px;
}

.no-txt {
  font-family: "Clash Grotesk";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #acacac;
  margin: 0;
}

.upload-container {
}

.upload-section {
  height: 170px;
}

.tool-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tool-button:hover {
  background-color: #e0e0e0;
}

.pill-button {
  border-radius: 10px; /* Curved edges */
  width: 145px; /* Set a fixed width */
  transition: transform 0.3s ease; /* Add transition for smooth effect */
}

.pill-button:hover {
  transform: translateY(-3px); /* Move up by 5px on hover */
}

.gradient-outline {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    to right,
    #ff7ecb,
    #a56eff,
    #6ecbff,
    #ffb56e
  );
  background-color: transparent; /* Ensure the background is transparent */
  color: inherit; /* Keep the text color as it is */
  transition: border-color 0.3s ease;
}

.gradient-outline:hover {
  border-image-source: linear-gradient(
    to right,
    #ffb56e,
    #6ecbff,
    #a56eff,
    #ff7ecb
  );
  color: #000;
  background-color: #f7f7f7;
}

.resize-modal-button {
  background: #f453c4;
  border-color: #f453c4;
}

.resize-modal-button:hover {
  background: #f453c4;
  border-color: #f453c4;
}

.resize-modal-cancel-button:hover {
  color: #f453c4;
  border-color: #f453c4;
}
