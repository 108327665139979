.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  /* Tinted overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;
  /* Ensure it sits above other content */
  border-radius: 10px;
}

/* Flex container with start alignment */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* Aligns items to the start of the container */
  margin-right: -10px;
  margin-left: -10px;
}

/* Fixed-width cards with fixed margin */
.custom-card {
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  margin: 10px 0;
  flex: 1 1 auto;
}

.custom-card .nft__item {
  padding: 0px;
  width: 100%;
  height: 100%;
  background: rgb(245, 245, 245);
  border-radius: 10px;
}

.custom-card .nft__item:hover {
  background: rgb(220, 220, 220);
  /* Darker shade of grey */
}

.overlay .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 16px;
  padding-right: 14px;
  cursor: pointer;
  color: #000;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #000;
}

.overlay-content > div:first-of-type {
  margin-bottom: 0;
}

.overlay-icon {
  width: 20px !important;
  height: 20px !important;
}

.nft__item_action--margin-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft__item_action--margin-bottom span {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}

.nft__item_action--flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft__item_action--flex span {
  margin-left: 10px;
}

.nft__item_wrap {
  position: relative;
  margin-top: 0px;
  border-radius: 10px;
  overflow: hidden;
}

.image_div {
  margin: 0 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
}

.nft__item_info {
  padding-bottom: 20px;
}

.nft__item_info span {
  display: block;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-end {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  z-index: 3;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.custom-card .card-actions {
  display: flex;
  align-items: left;
  background-color: #fff;
  /* Adjust based on your design */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  padding: 8px;
  position: absolute;

  width: 80%;
  /* Adjust as needed */
}

.custom-card .action-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #333;
  /* Icon color, adjust as needed */
  /* add 10px padding right */
  padding-right: 10px;
}

.custom-card .action-button .fa {
  font-size: 24px;
  /* Icon size, adjust as needed */
  margin-right: 8px;
  /* Spacing between icon and count, if applicable */
}

.custom-card .action-button span {
  font-size: 14px;
  /* Adjust as needed */
}

.cards-container {
  width: 100%;
  /* Ensure it spans the full width */
  display: flex;
  justify-content: center;
  /* Center the cards if needed */
}

.inner-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
}

.dummy-card {
  visibility: hidden;
  width: 180px;
  /* Match the width of your other cards */
  margin: 10px;
  /* Match the margin of your other cards */
}

.files-title {
  font-family: "Clash Grotesk";
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  color: #2a2a2a;
  margin-bottom: 0;
  margin-left: 15px;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #b8cbdc;
  padding: 50px 20px;
  /* transparent background */
  background: transparent;
}

.no-txt {
  font-family: "Clash Grotesk";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #acacac;
  margin: 0;
}

.all-title {
  padding-top: 20px; /* Add desired padding value */
  padding-left: 10px; /* Add desired padding value */
  font-family: "Clash Grotesk";
}

/* Override default antd modal styles */
.ant-modal {
  width: auto !important;
}

/* Custom modal container */
.custom-modal-container {
  text-align: center;
  padding: 16px 32px;
}

/* Modal title */
.custom-modal-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
}

/* Modal message */
.custom-modal-message {
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 24px;
}

/* Button container */
.custom-modal-buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
}

/* Base button styles */
.custom-modal-button {
  flex: 1;
  padding: 8px 16px;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 500;
  color: #f453c4;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 8px;
  text-transform: uppercase;
}

.custom-modal-button:hover {
  background-color: #f5f5f5;
}

.custom-modal-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
