.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  width: calc(100% - 50px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  padding: 10px 1rem;
  background-color: white;
  margin: 20px auto;
}

.plan-selection {
  margin: 20px 0;
}

.plan-option {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-color: #f053c1;
}

/* .plan-option.selected {
  background-color: #f0f8ff;
  box-shadow: 0 4px 20px rgba(76, 141, 255, 0.2);
} */

.plan-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.plan-details {
  display: flex;
  align-items: center;
}

.plan-icon {
  font-size: 24px;
  margin-right: 12px;
  color: #f053c1;
}

.plan-label {
  font-weight: 600;
  font-size: 18px;
  color: #333;
}

.best-value-badge {
  background-color: #44c4a1;
  color: white;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 12px;
}

.plan-price {
  text-align: right;
}

.price {
  font-weight: 700;
  font-size: 20px;
  color: #333;
}

.per-month {
  display: block;
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}
