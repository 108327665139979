.editor-footer {
  background: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
}

.zoom-control {
  display: flex;
  align-items: center;
  background: rgba(240, 240, 240, 0.6);
  padding: 2px 12px;
  border-radius: 8px;
  width: 140px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between;
}

.zoom-button {
  background: rgba(240, 240, 240, 0.6);
  color: #333;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
}

.zoom-button:hover {
  background: rgba(230, 230, 230, 0.6);
  color: #000;
}

.reset-button,
.reset-button:active,
.reset-button:focus {
  background: rgba(240, 240, 240, 0.6);
  color: #333;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 16px;
  height: 32px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.reset-button:hover {
  background: rgba(230, 230, 230, 0.6);
  transform: translateY(-1px);
  color: #000;
}
