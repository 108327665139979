.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 550px;
  width: calc(100% - 50px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  padding: 50px 1rem;
  background-color: white;
}

.container_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: fixed; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* max-width: 550px; */
  width: calc(100%);
  backdrop-filter: blur(10px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.logo {
  margin-bottom: 1rem;
}

.error {
  /* make the primary color */
  color: #ff4961;
  margin-top: 1rem;
  max-width: 300px;
  text-align: center;
  /* make it italic */
  font-style: italic;
}

.form {
  width: 100%;
  max-width: 360px;
}

.submit-button {
  color: white;
  margin-top: 10px;
}

.submit-button-2 {
  margin-top: 10px;
}

.links {
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background {
  --background: linear-gradient(180deg, #ffeffb 0%, #d8d8d8 100%);
  --background-size: 100% 100%;
}
.forgot_password {
  margin-top: 4px;
  color: rgba(87, 87, 87, 1);
  font-weight: 500;
  text-decoration: underline;
  /* text-underline-offset: 4px; */
  text-align: right;
  width: 100%;
}

.sub_total {
  background-color: #f5f5f5;
  margin-top: 40px;
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  display: flex;
  padding: 20px;
}

.sub_total > div {
  width: 75%;
}

.sub_total > div > h5 {
  font-size: 16px;
  margin: 0;
  color: #525252;
}

.sub_total > div > p {
  margin: 0;
  margin-top: 10px;
  line-height: 20px;
  color: #525252;
  font-size: 14px;
}

.sub_total > h3 {
  color: #525252;
  margin-left: auto;
  font-size: 20px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top > h3 {
  margin: 0;
  /* font-size: 32px; */
  font-weight: 500;
}

.top > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: rgba(234, 138, 4, 1);
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  color: white;
}

.onetimebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: rgba(234, 138, 4, 1);
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  color: white;
}

.bnfts {
  display: flex;
  flex-direction: column;
  /* gap: 18px; */
  margin-top: 10px;
}

.bnf {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px;
  padding: 0px;
}
.social-buttons-container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.social-buttons-container > IonButton {
  flex: 1 1 50%; /* Each button takes up 50% of the container width */
  margin: 0 2px; /* Optional: Adds a small space between the buttons */
  font-size: 12px; /* Smaller text size for the buttons */
}
