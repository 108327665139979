.parent1 {
  position: relative;
}

.input-1 {
  width: 100%;
  border-radius: 6px;
  background: transparent;
  outline: none;
  background-color: rgb(248, 248, 248);
  border: 1px solid rgba(222, 222, 222, 1);
  padding: 16px;
  padding-left: 52px;
  font-size: 18px;
}

.input-1:focus {
  border: 1px solid black;
  background-color: white;
}

.input_icon {
  position: absolute;
  padding: 0 0 0 20px;
  color: rgba(123, 123, 123, 1);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.input-1:focus + .input_icon {
  color: rgba(52, 52, 52, 1);
}

.error1 {
  margin-top: 4px;
  display: flex;
  align-items: start;
  color: rgba(243, 35, 35, 1);
  gap: 4px;
}

.input_error {
  border: 1px solid red;
}

.auth_button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 56px;
  font-size: 20px;
  border-radius: 6px;
  padding: 18px;
  margin-top: 10px;
  color: white;
  background: rgba(244, 83, 196, 1);
}

.auth_button:hover {
  background: rgb(247, 71, 194);
}

.auth_button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.prompt_text {
  margin: 0;
  text-align: center;
  color: rgba(123, 123, 123, 1);
}

.cta_text {
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
}
