#tabBar {
  margin-top: 60px !important;
  display: flex;
  justify-content: flex-start;
  /* Aligns items to the left */
  border-bottom: none;
}

ion-tab-button {
  color: #343434;
  font-size: 14px;
  font-weight: 400;
}

ion-tab-button.tab-selected {
  color: #2a2a2a;
  font-weight: 600;
  border-bottom: 2px solid #f453c4;
}

.nav-header {
  background: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.ant-tabs {
  font-family: "Clash Grotesk";
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: #343434 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "Clash Grotesk";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #2a2a2a !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.item-inner {
  border: none !important;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  padding: 0 10px !important;
}

.ant-upload.ant-upload-drag {
  border: none !important;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: transparent !important;
}

#export-modal #ion-react-wrapper {
  padding: 20px;
}

#export-modal .toolbar-container {
  min-height: auto !important;
  border: 1px solid green;
}

#export-modal ion-toolbar {
  --min-height: auto;
  line-height: normal;
}

#export-modal ion-input.file-name-input {
  --padding-bottom: 10px;
  --padding-end: 12px;
  --padding-start: 12px;
  --padding-top: 10px;
  border: 1px solid #aaa;
  border-radius: 6px;
  font-size: 14px;
  font-family: "DM Sans";
  color: "#343434";
  height: 40px;
}
