.Pro_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.inner_container {
  display: flex;
  width: 100%;
}

.inner_container.full_width {
  justify-content: center; /* Center content if needed */
}

.inner_container.full_width .right {
  width: 100%; /* Make the right side take full width */
}

.left {
  width: 50%;
  background-image: url("../../assets/lifetimebackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fffafd;
  border-right: 1px solid rgb(238, 234, 234);
}

.left > div {
  padding: 60px;
}

.left > img {
  position: absolute;
  bottom: 0;
}

.right {
  z-index: 1000;
  background-color: white;
  width: 50%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex: 1; /* Allow the right side to grow */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top > h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 500;
}

.paragraph {
  font-size: 18px;
  color: rgba(82, 82, 82, 1);
  margin: 0;
}

.bnfts {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 10px;
}

.bnf {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sub_total {
  background-color: #f5f5f5;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  display: flex;
  padding: 20px;
}

.sub_total > div {
  width: 75%;
}

.sub_total > div > h5 {
  font-size: 20px;
  margin: 0;
  color: #525252;
}

.sub_total > div > p {
  margin: 0;
  margin-top: 10px;
  line-height: 20px;
  color: #525252;
}

.sub_total > h3 {
  color: #525252;
  margin-left: auto;
  font-size: 24px;
}

.plan_toggle {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the plan toggle */
  margin-top: 20px;
}

.plan_toggle span {
  margin: 0 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px; /* Increase width */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 10px; /* Match height for full circle */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px; /* Adjust size */
  width: 26px; /* Adjust size */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(30px); /* Adjust for new width */
}

.save {
  color: magenta;
}
