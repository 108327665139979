.card-header-auth {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.logo-auth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-auth-custom {
  justify-content: start;
}

.pro-tag {
  padding: 6px 10px;
  background: linear-gradient(
    250.46deg,
    #fff6dc 0%,
    #f8f8f8 52.36%,
    #fff6dc 100%
  );
  border-radius: 8px;
  border: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.pro-tag > p {
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  color: rgba(82, 82, 82, 1);
}

.warm-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.warm-welcome > p:first-child {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: rgba(42, 42, 42, 1);
}

.warm-welcome > p:last-child {
  margin: 0;
  color: rgba(82, 82, 82, 1);
}
