.image-gen-container-wrapper {
  display: flex;
  justify-content: center;
}

.image-gen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  width: 100%;
  gap: 30px;
  margin: 20px;
}
