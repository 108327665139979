.custom-modal-container {
    padding: 32px;
    max-width: 480px;
    margin: 0 auto;
    background: linear-gradient(to right bottom, #FFFFFF, #FAFAFA);
    border-radius: 16px;
  }
  
  .custom-modal-title {
    font-family: "Clash Grotesk";
    font-size: 24px;
    font-weight: 500;
    color: #2A2A2A;
    text-align: center;
    margin-bottom: 16px;
  }
  
  .custom-modal-message {
    font-family: "Clash Grotesk";
    font-size: 14px;
    color: #484848;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .error-message {
    background: #FFF1F0;
    border: 1px solid #FFA39E;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #CF1322;
    font-size: 14px;
  }
  
  .quick-resize {
    margin: 24px 0;
  }
  
  .quick-resize-label {
    font-family: "Clash Grotesk";
    font-size: 14px;
    color: #484848;
    margin-bottom: 12px;
  }
  
  .quick-resize-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
  
  .quick-resize-btn {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background: linear-gradient(145deg, #FFFFFF, #F8F8F8);
    color: #2A2A2A;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .quick-resize-btn:hover {
    background: linear-gradient(145deg, #F8F8F8, #FFFFFF);
    border-color: #F453C4;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(244, 83, 196, 0.15);
  }
  
  .dimensions-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .dimension-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .dimension-label {
    font-family: "Clash Grotesk";
    font-size: 14px;
    color: #484848;
  }
  
  .dimension-input .ant-input {
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    padding: 8px 12px;
    transition: all 0.3s ease;
  }
  
  .dimension-input .ant-input:hover {
    border-color: #F453C4;
  }
  
  .dimension-input .ant-input:focus {
    border-color: #F453C4;
    box-shadow: 0 0 0 2px rgba(244, 83, 196, 0.2);
  }
  
  .aspect-ratio-checkbox {
    margin: 24px 0;
  }
  
  .aspect-ratio-checkbox .ant-checkbox-wrapper {
    font-family: "Clash Grotesk";
    font-size: 14px;
    color: #484848;
  }
  
  .aspect-ratio-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #F453C4;
    border-color: #F453C4;
  }
  
  .modal-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding: 24px;
    margin: 32px -32px -32px;
    background: #F8F9FA;
    border-top: 1px solid #E8E8E8;
  }
  
  .cancel-btn {
    padding: 12px 24px;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background: white;
    color: #2A2A2A;
    font-family: "Clash Grotesk";
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .cancel-btn:hover {
    background: #F8F9FA;
    border-color: #D9D9D9;
  }
  
  .resize-btn {
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    background: linear-gradient(90deg, #F453C4 0%, #E646B6 100%);
    color: white;
    font-family: "Clash Grotesk";
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .resize-btn:hover {
    background: linear-gradient(90deg, #E646B6 0%, #D639A7 100%);
    box-shadow: 0 2px 8px rgba(244, 83, 196, 0.25);
    transform: translateY(-1px);
  }
  
  .resize-btn:active {
    transform: translateY(0);
    box-shadow: none;
  }
  .success-container {
    text-align: center;
    padding: 20px 0;
  }
  
  .resize-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }