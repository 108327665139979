.no-animation-tabs .ant-tabs-ink-bar {
  transition: none !important;
  background-color: #f453c4 !important; /* Underline color set to blue */
  position: absolute !important;
  bottom: 5px !important; /* Underline 10px from the bottom of the text */
}

.no-animation-tabs .ant-tabs-tab {
  font-size: 16px !important;
  position: relative; /* Ensure parent is positioned relatively */
}

.no-animation-tabs .ant-tabs-tab-active {
  font-size: 16px !important; /* Set active tab font size to match other tabs */
}
