@keyframes movingDiagonalGradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.buymore-btn {
  background-color: transparent;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1.2px;
  text-decoration: underline;
  font-weight: 700;
  text-shadow: 1px 1px 1px #000000;
  text-underline-offset: 4px;
  position: absolute;
  right: 10px;
  top: 50%;
  color: white;
  transform: translateX(90px) translateY(-50%);
}

@media (max-width: 768px) {
  .buymore-btn {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.circular-button {
  border-radius: 20%;
  /* Makes the button round */
  padding: 10px;
  /* Adjust padding to maintain the circle shape */
  width: 50px;
  /* Set a fixed width */
  height: 45px;
  /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload-btn > p {
  margin: 0px;
  line-height: normal;
}

.upload-label {
  background-color: rgba(255, 255, 255, 0.3);
  /* Updated to add transparency */
  border-radius: 8px;
  /* Adjusted for curved rectangle edges */
  color: black;
  font-weight: bold;
  padding: 10px 16px;
  /* Increased padding */
  cursor: pointer;
  border: 2px dotted white;
  /* Added dotted outline */
}

.upload-label:hover {
  background-color: rgba(227, 227, 231, 0.3);
  /* Updated to add transparency on hover */
}

.dropactive {
  cursor: grab;
  background-color: rgb(9, 156, 9);
}

.tabs-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.tabs {
  text-align: center;
}

.tabs > p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.active {
  font-weight: 800 !important;
  /* color: white; */
}

.selected-icon {
  cursor: pointer;
  border-radius: 50%;
  padding: 12px;
  border: none;
  box-shadow:
    0 0 0 2px transparent,
    0 0 0 4px transparent,
    0 0 8px rgba(0, 0, 0, 0.1),
    0 0 0 4px rgba(255, 255, 255, 0.5) inset;
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  z-index: 1;
  width: 15px;
  height: 15px;
  margin: 0 10px;
}

.non-selected-icon {
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  border: none;
  box-shadow:
    0 0 0 2px transparent,
    0 0 0 4px transparent,
    0 0 8px rgba(0, 0, 0, 0.1),
    0 0 0 4px rgba(255, 255, 255, 0.1) inset;
  /* WebkitTextFillColor: "transparent", */
  position: relative;
  z-index: 1;
  width: 15px;
  height: 15px;
  margin: 0 10px;
}

/* New styles for antd Upload.Dragger to match the custom upload button */
.ant-upload.ant-upload-drag {
  background-color: rgba(255, 255, 255, 0.2);
  /* Match the previous upload-label background */
  border-radius: 8px;
  border: 2px dotted rgba(255, 255, 255, 0.5);
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  background-color: rgba(227, 227, 231, 0.3);
}

/* Style the text inside the Dragger for consistency */
.ant-upload-text {
  color: black;
  font-weight: bold;
}

/* Adjust the icon size and color if necessary */
.ant-upload-drag-icon {
  font-size: 16px;
  /* Adjust as needed */
  color: #000;
  /* Adjust icon color as needed */
}

/* When active/dragging over */
.ant-upload.ant-upload-drag.dropactive {
  background-color: rgb(9, 156, 9);
  /* For when an item is being dragged over */
  cursor: grab;
  /* Cursor style */
}

.ant-input {
  background-color: transparent;
  padding-left: 4px !important;
  font-weight: 400;
}

.prefix-img {
  background: #494f71;
  border: 1px solid #e4e4e461;
  padding: 2px 12px;
  border-radius: 6px;
}

.prefix-img-focused {
  border: 1px solid #e4e4e461;
  background: #d6d6d6;
}

.prefix-img-typing {
  border: 1px solid #e4e4e461;
  background: #d6d6d6;
}

.input-field {
  border-radius: 6px;
  flex: 1;
  background-color: transparent;
  color: #fff;
  border: 1px solid #e4e4e461;
  box-shadow: none;
  backdrop-filter: blur(2px);
  outline: none;
  padding: 2px;
}

/* Style for when the input is focused */
.input-field.focused {
  border: 1px solid #f5f5f561;
  background: #f8f8f8d1;
}

/* Style for when the user is typing */
.input-field.typing {
  border: 1px solid #f5f5f561;
  background: #f8f8f8d1;
}

.svg-trace {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 8px;
  left: 10px;
}

.svg-trace img {
  width: 24px;
  height: 24px;
}

.svg-trace h3 {
  margin: 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
  font-family: "Clash Grotesk";
}

.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background: transparent;
}

.generate-image-title {
  color: #fff;
  font-family: "Clash Grotesk";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  text-shadow: 1px 1px 2px #000000;
  margin-right: 10px;
  margin-bottom: 0;
}

.ant-form-item-explain-error {
  font-weight: 400;
}
