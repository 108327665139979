.modal-body {
  margin: 20px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.ant-modal-content {
  border-radius: 10px;
}

.cards {
  display: flex;
  gap: 10px;
}

.card {
  flex: 1;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 14px 0px;
}

.logo-part {
  display: flex;
  gap: 8px;
  align-items: center;
}

.logo-part > h5 {
  margin: 0px;
}

.card > p {
  margin: 0px;
  font-size: 13px;
  opacity: 0.7;
  font-weight: bold;
}

.selected {
  border: 1px solid #ffd700 !important;
}

.card.selected > p {
  opacity: 1;
}

.total {
  font-size: 14px;
  margin: 0px;
  font-weight: 600;
  color: gray;
}

.total > span {
  font-weight: 800;
  color: black;
}

.checkout-button {
  width: fit-content;
  color: white;
  text-transform: none;
  margin: 0px;
}
