.toolbarDiv {
  /* height: 150px; */
  /* overflow: scroll; */
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;

  /* for Firefox */

  /* set scrollbar display to none */
  /* overflow: hidden; */
}

.toolbarDiv::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.ant-tooltip-arrow {
  display: none;
}

.ant-tooltip-inner {
  background-color: transparent;
  box-shadow: none;
}
