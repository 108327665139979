.cards-container {
  margin: 0 0;
  display: flex;
  justify-content: center;
  padding: 0 0;
}

.inner-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
}
@media only screen and (max-width: 1800px) {
  .inner-container {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media only screen and (max-width: 1600px) {
  .inner-container {
    grid-template-columns: repeat(7, 1fr);
  }
}
@media only screen and (max-width: 1400px) {
  .inner-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media only screen and (max-width: 1200px) {
  .inner-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media only screen and (max-width: 1000px) {
  .inner-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 800px) {
  .inner-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .inner-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.no-data > * {
  margin: 0;
  padding: 0;
}

.dropactive {
  background-color: rgb(211, 214, 214);
}

.upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  border: 2px solid rgb(158, 156, 156);
  border-style: dashed;
  border-radius: 6px;
  width: 400px;
  height: 200px;
}

.upload-box > p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  opacity: 60%;
  user-select: none;
}

.upload-box > span {
  user-select: none;
}

.browse-btn {
  background-color: transparent;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  color: blue;
}

.browse-btn:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}
