@media only screen and (min-width: 750px) {
  .jupapi {
    height: calc(100vh - 340px);

    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 750px) {
  .jupapi {
    height: calc(100%);

    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
  }
}

.jupapi:hover {
  overflow-y: auto;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* ion-content {--overflow: hidden} */
